import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}

  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
})

// Handle 401 for cookie auth

axiosIns.interceptors.response.use(response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.dispatchEvent(new Event('auth:unauthorized'))
    }
    if (error.response && error.response.status >= 500) {
      window.dispatchEvent(new Event('network:serverError'))
    }
    return error.response
  })

axiosIns.interceptors.request.use(request => request, () => {
  window.dispatchEvent(new Event('network:globalError'))
})

Vue.prototype.$http = axiosIns

export default axiosIns
