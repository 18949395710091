import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    list: null,
    enrolments: {},
    invoices: {},
    files: {},
    calendar: null,
  },
  mutations: {
    setTrainingCourseList(state, payload) {
      state.list = payload
    },

    setTrainingCourseEnrolments(state, payload) {
      Vue.set(state.enrolments, payload.id, payload.data)
    },

    setTrainingCourseInvoices(state, payload) {
      Vue.set(state.invoices, payload.id, payload.data)
    },

    setTrainingCourseFiles(state, payload) {
      Vue.set(state.files, payload.id, payload.data)
    },

    updateAttendance(state, payload) {
      const index = state.enrolments[payload.params.trainingCourseId]['hydra:member'].findIndex(enrolment => enrolment.id === payload.params.id)
      Vue.set(state.enrolments[payload.params.trainingCourseId]['hydra:member'], index, payload.data)
    },

    setCalendar(state, payload) {
      state.calendar = payload
    },
  },
  actions: {
    fetchList(store, params) {
      const request = axios.get('training_courses', params)
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setTrainingCourseList', response.data)
        }
      })
      return request
    },

    fetchTrainingCourse(store, params) {
      return axios.get(`training_courses/${params.id}`)
    },

    addTrainingCourse(store, params) {
      return axios.post('training_courses', params)
    },

    editTrainingCourse(store, params) {
      return axios.put(`training_courses/${params.id}`, params.payload)
    },

    deleteTrainingCourse(store, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`training_courses/${params.id}`).then(response => {
          if (response && response.status === 204) {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    deleteEnrolment(store, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`enrolments/${params.id}`).then(response => {
          if (response && response.status === 204) {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(e => {
          reject(e)
        })
      })
    },

    fetchEnrolmentsForTrainingCourse(store, id) {
      const request = axios.get('enrolments', { params: { trainingCourse: id } })
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setTrainingCourseEnrolments', { id, data: response.data })
        }
      })
      return request
    },

    fetchInvoicesForTrainingCourse(store, id) {
      const request = axios.get('invoices', { params: { 'enrolments.trainingCourse': id } })
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setTrainingCourseInvoices', { id, data: response.data })
        }
      })
      return request
    },

    fetchFilesForTrainingCourse(store, id) {
      const request = axios.get(`training_courses/${id}/files`)
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setTrainingCourseFiles', { id, data: response.data })
        }
      })
      return request
    },

    addEnrolment(store, params) {
      return axios.post('enrolments', {
        trainingCourse: params.trainingCourse,
        user: params.user,
      })
    },

    sendTrainingCourseMessage(store, params) {
      return axios.post('training_course_messages', {
        trainingCourse: params.id, message: params.message,
      })
    },

    setAttendance(store, params) {
      return axios.put(`enrolments/${params.id}/edit_attendance`, {
        attendance: params.attendance,
        comment: params.comment,
      }).then(response => {
        if (response && response.status === 200) {
          store.commit('updateAttendance', { params, data: response.data })
        }
      })
    },

    // setTrainingCourseFiles(store, params) {
    //   return axios.put("training_courses/" + params.id + "/files", {
    //     mediaObject:
    //   })
    // }

    fetchPollResults(store, params) {
      return axios.get(`training_courses/${params.id}/poll_results`)
    },

    fetchCalendar(store, params) {
      return axios.get('training_courses/calendar', params).then(response => {
        if (response && response.status === 200) {
          store.commit('setCalendar', response.data)
        }
      })
    },
  },
}
