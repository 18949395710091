import axios from '@axios'

export default {
  namespaced: true,
  state: {
    list: null,
  },
  mutations: {
    setDealerList(state, payload) {
      state.list = payload
    },
  },
  actions: {
    fetchList(store, params) {
      const request = axios.get('dealers', params)
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setDealerList', response.data)
        }
      })
      return request
    },

    fetchDealer(store, params) {
      return axios.get(`dealers/${params.id}`)
    },

    addDealer(store, params) {
      return axios.post('dealers', params)
    },

    editDealer(store, params) {
      return axios.put(`dealers/${params.id}`, params.payload)
    },

    deleteDealer(store, params) {
      return axios.delete(`dealers/${params.id}`)
    },
  },
}
