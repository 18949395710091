import Vue from 'vue'
import resolveUserRoles from '@/helpers/resolveUserRoles'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import dayjs from 'dayjs'
import nl2br from 'nl2br'

export const userRole = function userRole(roles) {
  return resolveUserRoles(roles).text
}
Vue.filter('userRole', userRole)

export const date = function date(input) {
  const d = dayjs(input)
  // return `${d.getFullYear()}-${d.getMonth().toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
  // return `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${d.getFullYear()}`
  return d.format('YYYY-MM-DD')
}
Vue.filter('date', date)

export const time = function time(input) {
  const d = dayjs(input)
  // return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
  return d.format('HH:mm')
}
Vue.filter('time', time)

export const dateTime = function dateTime(input) {
  return `${date(input)} ${time(input)}`
}
Vue.filter('dateTime', dateTime)

export const priceInGrosze = function priceInGrosze(input) {
  return `${(parseInt(input, 10) / 100).toFixed(2)} PLN`.replace('.', ',')
}
Vue.filter('priceInGrosze', priceInGrosze)

Vue.use(vueFilterPrettyBytes)
Vue.filter('nl2br', nl2br)

export default {}
