import axios from '@axios'

export default {
  namespaced: true,
  state: {
    userData: JSON.parse(localStorage.getItem('userData') || null),
  },

  mutations: {
    setUserData(state, payload) {
      localStorage.setItem('userData', JSON.stringify(payload))
      state.userData = payload
    },
  },

  actions: {
    login(store, params) {
      return axios.post('login', params)
    },

    logout(store) {
      store.commit('setUserData', null)
      return axios.get('logout')
    },

    fetchUserData(store, params) {
      const request = axios.get('users/me', params)
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setUserData', response.data)
        }
      })
      return request
    },

    sendPasswordResetRequest(store, params) {
      return axios.post('user_password_reset_requests', params)
    },

    resetPassword(store, params) {
      return axios.post(`user_password_reset_requests/${params.id}/change_password`, params.payload)
    },

    editUserData(store, params) {
      return new Promise((resolve, reject) => {
        axios.put('users/me/edit', params.payload).then(response => {
          if (response && response.status === 200) {
            store.commit('setUserData', response.data)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => reject(error))
      })
    },

    changePassword(store, params) {
      return axios.put('users/me/change_password', params)
    },
  },
}
