export default {
  computed: {
    currentUser() {
      const data = this.$store.state.auth.userData
      // if (!data) {
      //   window.dispatchEvent(new Event('auth:unauthorized'))
      //   return null
      // }
      return data
    },
    isAdmin() {
      return this.currentUser && this.currentUser.roles.includes('ROLE_ADMIN')
    },
    isManager() {
      return this.currentUser && this.currentUser.roles.includes('ROLE_MANAGER')
    },
    isFordPoland() {
      return this.currentUser && this.currentUser.roles.includes('ROLE_FORD_POLAND')
    },
    isTrainer() {
      return this.currentUser && this.currentUser.roles.includes('ROLE_TRAINER')
    },
    isEmployee() {
      return this.currentUser && this.currentUser.roles.includes('ROLE_EMPLOYEE')
    },
  },
}
