import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Pulpit',
        hideHeader: true,
      },
    },
    {
      path: '/',
      redirect: '/users/list',
    },
    {
      path: '/users/list',
      name: 'users-list',
      component: () => import('@/views/users/UsersList.vue'),
      meta: {
        pageTitle: 'Lista pracowników',
        breadcrumb: [
          {
            text: 'Pracownicy',
            to: '/users',
            active: true,
          },
        ],
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_FORD_POLAND'],
      },
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/users/UsersEdit.vue'),
      meta: {
        pageTitle: 'Edycja pracownika',
        breadcrumb: [
          {
            text: 'Pracownicy',
            to: '/users',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
        roles: ['ROLE_ADMIN', 'ROLE_MANAGER'],
      },
    },
    {
      path: '/users',
      name: 'users',
      redirect: '/users/list',
    },
    {
      path: '/dealers/list',
      name: 'dealers-list',
      component: () => import('@/views/dealers/DealersList.vue'),
      meta: {
        pageTitle: 'Lista dealerów',
        breadcrumb: [
          {
            text: 'Dealerzy',
            to: '/dealers',
            active: true,
          },
        ],
        roles: ['ROLE_ADMIN', 'ROLE_FORD_POLAND'],
      },
    },
    {
      path: '/dealers/edit/:id',
      name: 'dealers-edit',
      component: () => import('@/views/dealers/DealersEdit.vue'),
      meta: {
        pageTitle: 'Edycja dealera',
        breadcrumb: [
          {
            text: 'Dealerzy',
            to: '/dealers',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
        roles: ['ROLE_ADMIN'],
      },
    },
    {
      path: '/dealers',
      name: 'dealers',
      redirect: '/dealers/list',
    },
    {
      path: '/training-courses/list',
      name: 'training-courses-list',
      component: () => import('@/views/training-courses/TrainingCoursesList.vue'),
      meta: {
        pageTitle: 'Lista szkoleń',
        breadcrumb: [
          {
            text: 'Szkolenia',
            to: '/training-courses',
            active: true,
          },
        ],
      },
    },
    {
      path: '/training-courses/new',
      name: 'training-courses-new',
      component: () => import('@/views/training-courses/TrainingCoursesAddNew.vue'),
      meta: {
        pageTitle: 'Nowe szkolenie',
        breadcrumb: [
          {
            text: 'Szkolenia',
            to: '/training-courses',
            active: false,
          },
          {
            text: 'Dodaj',
            active: true,
          },
        ],
        roles: ['ROLE_ADMIN'],
      },
    },
    {
      path: '/training-courses/edit/:id',
      name: 'training-courses-edit',
      component: () => import('@/views/training-courses/TrainingCoursesEdit.vue'),
      meta: {
        pageTitle: 'Edycja szkolenia',
        breadcrumb: [
          {
            text: 'Szkolenia',
            to: '/training-courses',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
        roles: ['ROLE_ADMIN', 'ROLE_TRAINER'],
      },
    },
    {
      path: '/training-courses/details/:id',
      name: 'training-courses-details',
      component: () => import('@/views/training-courses/TrainingCoursesDetails.vue'),
      meta: {
        pageTitle: 'Szczegóły szkolenia',
        breadcrumb: [
          {
            text: 'Szkolenia',
            to: '/training-courses',
            active: false,
          },
          {
            text: 'Szczegóły',
            active: true,
          },
        ],
      },
    },
    {
      path: '/training-courses',
      name: 'training-courses',
      redirect: '/training-courses/list',
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account/Account.vue'),
      meta: {
        pageTitle: 'Ustawienia konta',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
      meta: {
        pageTitle: 'Kalendarz',
        breadcrumb: [
          {
            text: 'Kalendarz',
            active: false,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Logowanie',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Przypomnienie hasła',
      },
    },
    {
      path: '/reset-password/:id/:token',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Resetowanie hasła',
      },
    },
    {
      path: '/finalize-account/:id/:token',
      name: 'finalize-account',
      component: () => import('@/views/authentication/FinalizeAccount.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Dokończ rejestrację',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Nie znaleziono strony',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Brak dostępu',
      },
    },
    {
      path: '/poll/:id/:uuid',
      name: 'poll',
      component: () => import('@/views/poll/Poll.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Ankieta poszkoleniowa',
      },
    },
    {
      path: '/poll/thanks',
      name: 'poll-thanks',
      component: () => import('@/views/poll/PollThanks.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Dziękujemy',
      },
    },
    {
      path: '/poll/already-submitted',
      name: 'poll-already-submitted',
      component: () => import('@/views/poll/PollAlreadySubmitted.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Ankieta wypełniona',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// page title
// can be overridden by AppBreadcrumb

const originalTitle = document.title

router.afterEach(route => {
  document.title = `${route.meta.pageTitle} - ${originalTitle}`
})

// ACL

router.beforeEach((to, from, next) => {
  if (to.meta.roles) {
    if (store.state.auth.userData) {
      if (store.state.auth.userData.roles.some(role => to.meta.roles.includes(role))) {
        next()
      } else {
        next('/error-403')
      }
    } else {
      next({ path: '/login', query: { to: to.fullPath } })
    }
  } else {
    next()
  }
})

export default router
