import axios from '@axios'

export default {
  namespaced: true,
  state: {
    list: null,
  },
  mutations: {
    setUserList(state, payload) {
      state.list = payload
    },
  },
  actions: {
    fetchList(store, params) {
      const request = axios.get('users', params)
      request.then(response => {
        if (response && response.status === 200) {
          store.commit('setUserList', response.data)
        }
      })
      return request
    },

    fetchUser(store, params) {
      return axios.get(`users/${params.id}`)
    },

    addUser(store, params) {
      return axios.post('users/invite', params)
    },

    editUser(store, params) {
      return axios.put(`users/${params.id}`, params.payload)
    },

    resendInvite(store, params) {
      // return new Promise((resolve, reject) => {
      //   axios.put(`users/${params.id}`, {
      //     status: 'INACTIVE',
      //   }).then(inactiveResponse => {
      //     if (inactiveResponse && inactiveResponse.status === 200) {
      //       axios.put(`users/${params.id}`, {
      //         status: 'INVITED',
      //       }).then(activeResponse => {
      //         if (activeResponse && activeResponse.status === 200) {
      //           resolve()
      //         } else {
      //           reject()
      //         }
      //       }).catch(() => reject())
      //     }
      //   }).catch(() => reject())
      // })

      return axios.post('users/invite_again', {
        email: params.email,
      })
    },

    confirmUser(store, params) {
      return axios.put(`users/${params.id}/complete_registration`, params.payload)
    },

    deleteUser(store, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`users/${params.id}`).then(response => {
          if (response && response.status === 204) {
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(e => {
          reject(e)
        })
      })
    },
  },
}
